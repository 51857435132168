var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home",staticStyle:{"background":"#F8FAFC"}},[_c('HomeComponent'),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"container py-5",staticStyle:{"padding-top":"10px !important"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('h5',{staticClass:"font-weight-bold mb-3 text-center text-lg-start",staticStyle:{"padding":"20px 40px 0 10px"}},[_vm._v("Ofrecimientos")]),_c('div',{staticClass:"card",staticStyle:{"background":"#F2F7FC"}},[_c('div',{staticClass:"card-body"},[_c('ul',{staticClass:"list-unstyled mb-0"},[_c('li',{staticClass:"p-2 border-bottom",staticStyle:{"background-color":"#eee"}},[_c('a',{staticClass:"d-flex justify-content-between"},[_vm._m(0),_c('div',{staticClass:"pt-1"},[_c('router-link',{staticClass:"btn btn-outline-secondary botton-solicitud",attrs:{"to":"/chat"}},[_vm._v("Ver Solicitud")])],1)])]),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6)])])])])])])]),_c('div',{staticClass:"col-6"},[_vm._m(7),_c('card-component')],1),_vm._m(8)])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex flex-row"},[_c('img',{staticClass:"rounded-circle d-flex align-self-center me-3 shadow-1-strong",attrs:{"src":"https://media.opticauniversitaria.es/media/wysiwyg/formas-de-cara/gafas-ovaladas.jpg","alt":"avatar","width":"60","height":"60"}}),_c('div',{staticClass:"pt-1"},[_c('p',{staticClass:"fw-bold mb-0 names-msg"},[_vm._v("John Doe")]),_c('p',{staticClass:"small text-muted"},[_vm._v("Aun estas buscando?")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"p-2 border-bottom"},[_c('a',{staticClass:"d-flex justify-content-between",attrs:{"href":"#!"}},[_c('div',{staticClass:"d-flex flex-row"},[_c('img',{staticClass:"rounded-circle d-flex align-self-center me-3 shadow-1-strong",attrs:{"src":"https://mdbcdn.b-cdn.net/img/Photos/Avatars/avatar-1.webp","alt":"avatar","width":"60"}}),_c('div',{staticClass:"pt-1"},[_c('p',{staticClass:"fw-bold mb-0 names-msg"},[_vm._v("Danny Smith")]),_c('p',{staticClass:"small text-muted"},[_vm._v("Yo lo tengo disponible.")])])]),_c('div',{staticClass:"pt-1"},[_c('button',{staticClass:"btn btn-outline-secondary botton-solicitud"},[_vm._v("Ver solicitud")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"p-2 border-bottom"},[_c('a',{staticClass:"d-flex justify-content-between",attrs:{"href":"#!"}},[_c('div',{staticClass:"d-flex flex-row"},[_c('img',{staticClass:"rounded-circle d-flex align-self-center me-3 shadow-1-strong",attrs:{"src":"https://mdbcdn.b-cdn.net/img/Photos/Avatars/avatar-2.webp","alt":"avatar","width":"60"}}),_c('div',{staticClass:"pt-1"},[_c('p',{staticClass:"fw-bold mb-0 names-msg"},[_vm._v("Alex Steward")]),_c('p',{staticClass:"small text-muted"},[_vm._v("Yo tengo uno.")])])]),_c('div',{staticClass:"pt-1"},[_c('button',{staticClass:"btn btn-outline-secondary botton-solicitud"},[_vm._v("Ver solicitud")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"p-2 border-bottom"},[_c('a',{staticClass:"d-flex justify-content-between",attrs:{"href":"#!"}},[_c('div',{staticClass:"d-flex flex-row"},[_c('img',{staticClass:"rounded-circle d-flex align-self-center me-3 shadow-1-strong",attrs:{"src":"https://mdbcdn.b-cdn.net/img/Photos/Avatars/avatar-3.webp","alt":"avatar","width":"60"}}),_c('div',{staticClass:"pt-1"},[_c('p',{staticClass:"fw-bold mb-0 names-msg"},[_vm._v("Ashley Olsen")]),_c('p',{staticClass:"small text-muted"},[_vm._v("Suuuper!!")])])]),_c('div',{staticClass:"pt-1"},[_c('p',{staticClass:"small text-muted mb-1"},[_vm._v("Yesterday")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"p-2 border-bottom"},[_c('a',{staticClass:"d-flex justify-content-between",attrs:{"href":"#!"}},[_c('div',{staticClass:"d-flex flex-row"},[_c('img',{staticClass:"rounded-circle d-flex align-self-center me-3 shadow-1-strong",attrs:{"src":"https://mdbcdn.b-cdn.net/img/Photos/Avatars/avatar-4.webp","alt":"avatar","width":"60"}}),_c('div',{staticClass:"pt-1"},[_c('p',{staticClass:"fw-bold mb-0 names-msg"},[_vm._v("Kate Moss")]),_c('p',{staticClass:"small text-muted"},[_vm._v("el lunes lo entrego")])])]),_c('div',{staticClass:"pt-1"},[_c('p',{staticClass:"small text-muted mb-1"},[_vm._v("Yesterday")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"p-2 border-bottom"},[_c('a',{staticClass:"d-flex justify-content-between",attrs:{"href":"#!"}},[_c('div',{staticClass:"d-flex flex-row"},[_c('img',{staticClass:"rounded-circle d-flex align-self-center me-3 shadow-1-strong",attrs:{"src":"https://mdbcdn.b-cdn.net/img/Photos/Avatars/avatar-5.webp","alt":"avatar","width":"60"}}),_c('div',{staticClass:"pt-1"},[_c('p',{staticClass:"fw-bold mb-0 names-msg"},[_vm._v("Lara Croft")]),_c('p',{staticClass:"small text-muted"},[_vm._v("saleee!")])])]),_c('div',{staticClass:"pt-1"},[_c('p',{staticClass:"small text-muted mb-1"},[_vm._v("Yesterday")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"p-2"},[_c('a',{staticClass:"d-flex justify-content-between",attrs:{"href":"#!"}},[_c('div',{staticClass:"d-flex flex-row"},[_c('img',{staticClass:"rounded-circle d-flex align-self-center me-3 shadow-1-strong",attrs:{"src":"https://mdbcdn.b-cdn.net/img/Photos/Avatars/avatar-6.webp","alt":"avatar","width":"60"}}),_c('div',{staticClass:"pt-1"},[_c('p',{staticClass:"fw-bold mb-0 names-msg",staticStyle:{"text-decoration":"none"}},[_vm._v("Brad Pitt")]),_c('p',{staticClass:"small text-muted"},[_vm._v("Gracias por todo!")])])]),_c('div',{staticClass:"pt-1"},[_c('p',{staticClass:"small text-muted mb-1"},[_vm._v("5 mins ago")]),_c('span',{staticClass:"text-muted float-end"},[_c('i',{staticClass:"fas fa-check",attrs:{"aria-hidden":"true"}})])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"width":"500px","margin-left":"170px"}},[_c('div',{staticClass:"input-group mb-3"},[_c('input',{staticClass:"form-control",staticStyle:{"border-color":"#2B0649","height":"45px","-moz-border-radius":"20px","-webkit-border-radius":"20px","position":"relative"},attrs:{"type":"text","placeholder":"Buscar...","aria-label":"Recipient's username","aria-describedby":"basic-addon2"}}),_c('div',{staticClass:"input-group-append w-50",staticStyle:{"width":"fit-content !important","position":"absolute","top":"6px"}},[_c('i',{staticClass:"fas fa-search",staticStyle:{"left":"470px","top":"7px","position":"absolute","z-index":"20000px"}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col"},[_c('h5',{staticClass:"font-weight-bold mb-3 text-center text-lg-start",staticStyle:{"padding":"36px 34px 0px 46px","text-align":"right !important"}},[_vm._v("Crear una publicación")]),_c('div',[_c('input',{staticClass:"ph",staticStyle:{"width":"90%","height":"250px","border-color":"#C5CFF3","border-top-left-radius":"18px","border-top-right-radius":"18px"},attrs:{"type":"textfield","placeholder":"¿Buscabas algo?"}})]),_c('div',[_c('button',{staticStyle:{"width":"90%","height":"50px","background":"#7E8DDF","border-color":"transparent","color":"#fff","font-size":"20px","font-weight":"600","border-bottom-left-radius":"18px","border-bottom-right-radius":"18px"}},[_vm._v("Publicar")])])])
}]

export { render, staticRenderFns }